<template>
  <v-dialog v-model="show" max-width="500px">
    <v-card class="conConfirmPopup">
      <v-card-title>
        <span class="text-h5 modalTitle">Are you sure</span>
      </v-card-title>
      <v-card-text class="textConfirm"
        >Are you sure ?</v-card-text
      >

      <form class="conFormSubmit mb-4" v-if="isRejectBtn">
        <v-row class="justify-center ma-0">
          <v-col cols="10">
            <v-textarea
              label="Reason"
              rows="3"
              class="eachInput"
              outlined
              solo
              hide-details="auto"
              v-model="rejectreason"
            >
            </v-textarea>
          </v-col>
        </v-row>
      </form>

      <v-card-actions class="pb-7">
        <v-spacer></v-spacer>
        <v-btn class="btnCancel" tile @click.stop="show = false">Cancel</v-btn>
        <v-btn
          class="btnPrimaryOrg"
          tile
          @click="cofirmAction"
          :disabled="isLoadingConfirmDialog"
          :loading="isLoadingConfirmDialog"
          >Confirm</v-btn
        >
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import store from "../../../../store/index";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],
  validations() {
    const validations = {
      Po: { required },
    };
    return validations;
  },
  data: () => ({
    isLoadingConfirmDialog: false,
    paymentPercentage: "",
    rejectreason: "",
    rejectreasonCeo: "",
    Po: null,
  }),
  props: {
    value: Boolean,
    isRejectBtn: Boolean,
    isApproveBtn: Boolean,
  },
  methods: {
    cofirmAction() {
      this.isLoadingConfirmDialog = true;

      store.commit("setRejectReason", this.rejectreason);

      this.$emit("confirmAction");
    },
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    ...mapGetters(["user"]),
  },
};
</script>
